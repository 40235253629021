import { render, staticRenderFns } from "./Intro.vue?vue&type=template&id=f08f041c&scoped=true&"
import script from "./Intro.vue?vue&type=script&lang=js&"
export * from "./Intro.vue?vue&type=script&lang=js&"
import style0 from "./Intro.vue?vue&type=style&index=0&id=f08f041c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f08f041c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VIcon,VImg,VRow})
